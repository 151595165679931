import { AxiosResponse } from 'axios'
import _ from 'lodash'

// import _ from 'lodash'
import { apiVersionURL, httpRequest, RequestBaseURL } from '../axios/http-common.service'
import { TeamTypes } from './teams.types'

export namespace TeamsAPI {
	export function findTeams(): Promise<AxiosResponse<TeamTypes.Teams>> {
		const headers = {}

		const requestPromise = httpRequest.get(`${RequestBaseURL}${apiVersionURL}/realestateteam/`, {
			headers,
		})
		return requestPromise
	}

	export function createTeam(teamToCreate: TeamTypes.Team): Promise<AxiosResponse<TeamTypes.Team>> {
		const headers = {}

		const payload: Partial<TeamTypes.Team> = _.cloneDeep(teamToCreate)
		delete payload.realEstateTeamId

		const requestPromise = httpRequest.post(`${RequestBaseURL}${apiVersionURL}/realestateteam/`, payload, {
			headers,
		})
		return requestPromise
	}

	export function updateTeam(
		realEstateTeamId: number,
		updatedProperties: Partial<TeamTypes.Team>,
	): Promise<AxiosResponse<TeamTypes.Team>> {
		const headers = {}

		const requestPromise = httpRequest.patch(
			`${RequestBaseURL}${apiVersionURL}/realestateteam/${realEstateTeamId}`,
			updatedProperties,
			{
				headers,
			},
		)
		return requestPromise
	}

	export function deleteTeam(teamToDelete: TeamTypes.Team): Promise<AxiosResponse<null>> {
		const headers = {}

		const requestPromise = httpRequest.delete(
			`${RequestBaseURL}${apiVersionURL}/realestateteam/${teamToDelete.realEstateTeamId}`,
			{
				headers,
			},
		)
		return requestPromise
	}

	export function getTeamById(id: number): Promise<AxiosResponse<TeamTypes.Team | null>> {
		const headers = {}

		const requestPromise = httpRequest.get(`${RequestBaseURL}${apiVersionURL}/realestateteam/${id}`, {
			headers,
		})
		return requestPromise
	}

	export function getTeamMembers(id: number): Promise<AxiosResponse<TeamTypes.TeamWithMembers | null>> {
		const headers = {}

		const requestPromise = httpRequest.get(`${RequestBaseURL}${apiVersionURL}/realestateteam/${id}/members`, {
			headers,
		})
		return requestPromise
	}

	export function updateTeamMembers(
		realEstateTeamId: number,
		endUsers: number[],
	): Promise<AxiosResponse<TeamTypes.Team>> {
		const headers = {}

		const requestPromise = httpRequest.put(
			`${RequestBaseURL}${apiVersionURL}/realestateteam/${realEstateTeamId}/members`,
			endUsers,
			{
				headers,
			},
		)
		return requestPromise
	}

	export function removeMemberFromTeam(
		realEstateTeamId: number,
		memberToRemove: number,
	): Promise<AxiosResponse<null>> {
		const headers = {}

		const requestPromise = httpRequest.delete(
			`${RequestBaseURL}${apiVersionURL}/realestateteam/${realEstateTeamId}/member/${memberToRemove}`,
			{
				headers,
			},
		)
		return requestPromise
	}
}
