import { httpRequest, RequestBaseURL } from '../axios/http-common.service'
import { BackAtYouTypes } from './back-at-you.types'

class BackAtYouAPIPrototype {
	async getBackAtYouToken(): Promise<BackAtYouTypes.GetBackAtYouTokenDTO> {
		try {
			const response = await httpRequest.get(`${RequestBaseURL}/api/v1/getBaySsoToken`)
			return response.data
		} catch (error) {
			console.log(error)
			throw new Error('Failed to get SSO URL.')
		}
	}
	async createAccount(endUserId: number) {
		try {
			const response = await httpRequest.post(`${RequestBaseURL}/api/v1/create-user-back-at-you/${endUserId}`)
			return response.data
		} catch (error) {
			console.log(error)
			throw new Error('Failed to create user in Back At You!')
		}
	}

	async deactivateUser(endUserId: number) {
		try {
			const response = await httpRequest.post(`${RequestBaseURL}/api/v1/deactivate-user-back-at-you/${endUserId}`)
			return response.data
		} catch (error) {
			console.log(error)
			throw new Error('Failed to deactivate user in Back At You!')
		}
	}

	async activateUser(endUserId: number) {
		try {
			const response = await httpRequest.post(`${RequestBaseURL}/api/v1/activate-user-back-at-you/${endUserId}`)
			return response.data
		} catch (error) {
			console.log(error)
			throw new Error('Failed to deactivate user in Back At You!')
		}
	}
}

export const BackAtYouAPI = new BackAtYouAPIPrototype()
