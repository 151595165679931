import './account__card.scss'

import { AdvertisingTypes } from 'src/services/advertising/advertising.types'

import { Utilities } from '../../../../services/utilities.service'

interface AccountCardProps {
	onClick: () => void
	onDoubleClick: () => void
}

export function AccountCard(props: AdvertisingTypes.Account & AccountCardProps) {
	return (
		<div
			className="account__card"
			onClick={props.onClick}
			onDoubleClick={props.onDoubleClick}
			style={{ userSelect: 'none' }}
		>
			<strong>{Utilities.truncateString(props.organizationName, 100)}</strong>
		</div>
	)
}
