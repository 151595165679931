import './teams___card.scss'

import { TeamTypes } from 'src/services/teams/teams.types'

interface TeamCardProps {
	onClick: () => void
	onDoubleClick: () => void
}

export function TeamCard(props: TeamTypes.Team & TeamCardProps) {
	return (
		<div
			className="team__card"
			onClick={props.onClick}
			onDoubleClick={props.onDoubleClick}
			style={{ userSelect: 'none' }}
		>
			<strong>{props.name}</strong>
		</div>
	)
}
