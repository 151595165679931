import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { useLoaderData } from 'react-router-dom'
import { TeamTypes } from 'src/services/teams/teams.types'

import { TeamMembersRoute } from './team-members'

export function TeamMembersRouteWrapper() {
	const data = useLoaderData() as TeamTypes.TeamWithMembers | null

	console.log(data)
	if (!data) {
		return (
			<RouteWrapperV2>
				<RouteBodyV2>Cannot load account</RouteBodyV2>
			</RouteWrapperV2>
		)
	}

	return <TeamMembersRoute team={data} />
}
