import { httpRequest, RequestBaseURL } from '../axios/http-common.service'

class AceAPIPrototype {
	async getSsoUrl(): Promise<string> {
		try {
			const params = {}
			const response = await httpRequest.get(`${RequestBaseURL}/api/v1/ace/getAceSsoUrl`, {
				params: params,
			})
			return response.data
		} catch (error) {
			console.log(error)
			throw new Error('Failed to get SSO URL.')
		}
	}
	async createUser(endUserId: number): Promise<string> {
		try {
			const response = await httpRequest.post(`${RequestBaseURL}/api/v1/ace/create-user/${endUserId}`, {})
			return response.data
		} catch (error) {
			console.log(error)
			throw new Error('Failed to create user in ACE.')
		}
	}

	async activateUser(endUserId: number): Promise<string> {
		try {
			const response = await httpRequest.post(`${RequestBaseURL}/api/v1/ace/activate-user/${endUserId}`, {})
			return response.data
		} catch (error) {
			console.log(error)
			throw new Error('Failed to activate user in ACE.')
		}
	}

	async deactivateUser(endUserId: number): Promise<string> {
		try {
			const response = await httpRequest.post(`${RequestBaseURL}/api/v1/ace/deactivate-user/${endUserId}`, {})
			return response.data
		} catch (error) {
			console.log(error)
			throw new Error('Failed to activate user in ACE.')
		}
	}
}

export const AceAPI = new AceAPIPrototype()
