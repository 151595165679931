import { LoKationButton } from '@components/button/button'
import { IconButton } from '@components/icon-button/icon-button'
import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteSubnavigation } from '@components/route-wrapper-with-sub-nav/route-subnavigation/route-subnavigation'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { RootState } from '@redux/store'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { UserSelectorModal } from 'src/modals/user-selector/user-selector'
import useAdminRedirect from 'src/services/hooks/user-admin-redirect'
import { TeamsAPI } from 'src/services/teams/teams.api'
import { TeamTypes } from 'src/services/teams/teams.types'
import { EndUserProps } from 'src/services/user/user.types'

type ConnectedProps = {
	userProfile: EndUserProps | null
	team: TeamTypes.TeamWithMembers
}

function TeamMembersRoute__Prototype(props: ConnectedProps) {
	const navigate = useNavigate()

	const [showSelectUsersModal, setShowSelectUsersModal] = useState(false)
	const [modifiedTeam, setModifiedTeam] = useState<TeamTypes.TeamWithMembers | null>(props.team)
	const [counter, setCounter] = useState<number>(0)

	useAdminRedirect(props.userProfile)

	useEffect(() => {
		TeamsAPI.getTeamMembers(props.team.realEstateTeamId).then((res) => {
			setModifiedTeam(res.data)
		})
	}, [counter])

	return (
		<>
			<RouteWrapperV2>
				<RouteSubnavigation
					title={`Teams Administration`}
					backButton={{
						label: 'Teams',
						onClick: () => {
							navigate('/administration/teams')
						},
					}}
					isCustom={true}
				>
					<></>
				</RouteSubnavigation>

				<RouteBodyV2 id="resources-admin__route-body">
					<div className="flex flex-alignItems-center flex-justifyContent-spaceBetween mb-10">
						<div>
							<h1>Manage Team: {props.team.name}</h1>
							<p>Team Leader: {props.team.teamLeaderName}</p>
						</div>
						<div className="flex flex-alignItems-center">
							<LoKationButton
								variant="outlined"
								label="Add/Remove members"
								size={'sm'}
								onClick={() => {
									setShowSelectUsersModal(true)
								}}
							/>
						</div>
					</div>
					<div className="mb-20 opacity-50">{modifiedTeam?.members.length} Results</div>

					{modifiedTeam &&
						modifiedTeam.members.map((teamMember) => {
							return (
								<div key={teamMember.endUserId}>
									<>
										<div className="form__card">
											<div>
												{teamMember.firstName} {teamMember.lastName}
											</div>
											<div className="flex">
												<IconButton
													icon={'user'}
													onClick={() => {
														navigate(`/administration/users/${teamMember.endUserId}`)
													}}
													className="mr-10"
												/>
												<IconButton
													icon={'trash'}
													onClick={() => {
														TeamsAPI.removeMemberFromTeam(
															props.team.realEstateTeamId,
															teamMember.endUserId,
														).then(() => {
															setCounter((prevState) => prevState + 1)
														})
													}}
												/>
											</div>
										</div>
									</>
								</div>
							)
						})}
				</RouteBodyV2>
			</RouteWrapperV2>

			{showSelectUsersModal && modifiedTeam && (
				<UserSelectorModal
					selectedRecipients={{
						endUsers: modifiedTeam.members.map((member) => member.endUserId),
						licensedStates: [],
						specialties: [],
						licensedRegions: [],
						endUsersByPaymentMethod: [],
					}}
					zIndex={10000}
					onHide={() => {
						setShowSelectUsersModal(false)
					}}
					onSelectUsers={(updatedRecipients) => {
						TeamsAPI.updateTeamMembers(props.team.realEstateTeamId, updatedRecipients.endUsers).then(() => {
							setCounter((prevState) => prevState + 1)
						})
						setShowSelectUsersModal(false)
					}}
					displayOptions={['user']}
				/>
			)}
		</>
	)
}

function mapStateToProps(state: RootState) {
	return {
		userProfile: state.user,
	}
}

export const TeamMembersRoute = connect(mapStateToProps)(TeamMembersRoute__Prototype)
