/* eslint-disable react/jsx-pascal-case */
import { LoKationButton } from '@components/button/button'
import { ReferralSearchAutocomplete } from '@components/referral-search-autocomplete/referral-search-autocomplete'
import { SideSheet } from '@components/side-sheet/side-sheet'
import { SideSheetServices } from '@components/side-sheet/side-sheet.service'
import { SideSheetTypes } from '@components/side-sheet/side-sheet.types'
import { SideSheet__Row } from '@components/side-sheet/side-sheet__row'
import { SideSheet__Section } from '@components/side-sheet/side-sheet__section'
import { TextInput } from '@components/text-input/text-input'
import _ from 'lodash'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { GenericDeleteConfirmationModal } from 'src/modals/generic-delete-confirmation/generic-delete-confirmation'
import { settingsRoutePath } from 'src/routes/routes.constant'
import { TeamsAPI } from 'src/services/teams/teams.api'
import { TeamTypes } from 'src/services/teams/teams.types'
import { EndUserFirstLast } from 'src/services/user/user.types'

interface TeamsSideSheetProps {
	team: TeamTypes.Team | 'NEW'
	onClose: () => void
	onDelete: () => void
	onUpdate: (updatedAccount: TeamTypes.Team) => void
}

export function TeamsSideSheet(props: TeamsSideSheetProps) {
	const navigate = useNavigate()
	const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false)
	const [modifiedState, setModifiedState] = useState(getInitialState())
	const didUserModifyProps = !_.isEqual(props.team, modifiedState)
	const isAccountValid = getIsTeamValid()
	const [referralSearchValue, setReferralSearchValue] = useState<string>('')
	const [listVisible, setListVisible] = useState(true)

	function getInitialState(): TeamTypes.Team {
		if (props.team === 'NEW') {
			const defaultTeam: TeamTypes.Team = {
				name: '',
				teamLeaderEndUserId: -1,
				realEstateTeamId: -1,
				teamLeaderName: '',
			}
			return defaultTeam
		}
		return props.team
	}

	function createFooter(): React.ReactNode {
		return (
			<>
				<LoKationButton
					variant={'outlined'}
					label={'Cancel'}
					size={'sm'}
					className="mr-10"
					onClick={() => {
						props.onClose()
					}}
				/>
				<LoKationButton
					variant={'contained'}
					label={'Save and Close'}
					disabled={!isAccountValid}
					size={'sm'}
					onClick={() => {
						handleSaveAccount().then(() => {
							props.onClose()
							props.onUpdate(modifiedState)
						})
					}}
				/>
			</>
		)
	}

	function createActions(): SideSheetTypes.Action[] {
		const actions: SideSheetTypes.Action[] = []

		if (props.team !== 'NEW') {
			actions.push({
				label: `Delete Team`,
				icon: 'trash',
				onClick: () => {
					setShowDeleteConfirmationModal(true)
				},
			})
		}

		return actions
	}

	function onScrimClick(): void {
		if (!didUserModifyProps) {
			props.onClose()
			return
		}

		SideSheetServices.showDismissConfirmationToast({
			onDismiss: () => {
				return new Promise((resolve) => {
					resolve()
					props.onClose()
				})
			},
			onSaveAndClose: () => {
				return handleSaveAccount().then(() => {
					props.onClose()
					props.onUpdate(modifiedState)
				})
			},
		})
	}

	function handleSaveAccount(): Promise<void> {
		return new Promise((resolve) => {
			if (modifiedState.realEstateTeamId < 0) {
				TeamsAPI.createTeam(modifiedState).then(() => {
					resolve()
				})
			} else {
				TeamsAPI.updateTeam(modifiedState.realEstateTeamId, modifiedState).then(() => {
					resolve()
				})
			}
		})
	}

	function updateProperties(changes: Partial<TeamTypes.Team>): void {
		const updatedProps: TeamTypes.Team = { ...modifiedState, ...changes }
		setModifiedState(updatedProps)
	}

	function getIsTeamValid(): boolean {
		if (modifiedState.name.length === 0) {
			return false
		}

		return true
	}

	const handleUserSelected = (user: EndUserFirstLast) => {
		setListVisible(false)
		if (user && user.endUserId > 0) {
			updateProperties({ teamLeaderEndUserId: user.endUserId })
			setReferralSearchValue(user.firstName + ' ' + user.lastName)
		}
	}

	return (
		<>
			<SideSheet
				title={'Team'}
				actions={createActions()}
				onClose={props.onClose}
				persistent={false}
				footer={createFooter()}
				preventDefaultScrimClick={didUserModifyProps}
				onScrimClick={onScrimClick}
			>
				<SideSheet__Section label="General" mode="expandable" sectionKey="account__general">
					<SideSheet__Row direction="column" label="Name">
						<TextInput
							type="text"
							dataType="text"
							value={modifiedState.name}
							width={'100%'}
							className="col-xs-12"
							onChange={(updatedValue) => {
								updateProperties({ name: updatedValue })
							}}
						/>
					</SideSheet__Row>
					<SideSheet__Row direction="column" label="">
						<div className="col-xs-12">
							<TextInput
								width={`100%`}
								dataType={'text'}
								label={'Team Leader'}
								value={referralSearchValue}
								onChange={(updatedValue) => {
									setListVisible(true)
									setReferralSearchValue(updatedValue)
								}}
							/>

							{listVisible && (
								<ReferralSearchAutocomplete
									onUserSelected={handleUserSelected}
									searchValue={referralSearchValue}
									endUserId={modifiedState.teamLeaderEndUserId}
								/>
							)}
						</div>
					</SideSheet__Row>
				</SideSheet__Section>

				<LoKationButton
					className="col-xs-12"
					variant={'contained'}
					label={'Open Team'}
					disabled={modifiedState.realEstateTeamId < 0}
					size={'lg'}
					onClick={() => {
						navigate(`${settingsRoutePath}/team/${modifiedState.realEstateTeamId}`)
					}}
				/>
			</SideSheet>
			{showDeleteConfirmationModal && (
				<GenericDeleteConfirmationModal
					itemLabel={modifiedState.name}
					onDelete={() => {
						TeamsAPI.deleteTeam(modifiedState).then(() => {
							setShowDeleteConfirmationModal(false)
							props.onDelete()
							props.onClose()
						})
					}}
					onClose={() => {
						setShowDeleteConfirmationModal(false)
					}}
				/>
			)}
		</>
	)
}
